import './App.scss';
import "tailwindcss/tailwind.css"
import Header from './components/Layout/Header'
import Intro from "./components/Sections/Intro";
import Content from "./components/Sections/Content";
import Footer from "./components/Layout/Footer";
import DiagramComponent from "./components/DiagramComponent";
import {AppProvider} from "./components/DiagramComponent/context";
import {useEffect, useState} from "react";

import MobileRotation from "./components/MobileRotation/index";

function App() {
    const [isMobile, setIsMobile] = useState(false);
    const mobileMax = 767;
    const handleReload = ()=>{
        window.scrollTo(0, 0);
    }
    useEffect(()=>{
      if(window.innerWidth <= mobileMax) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
        window.addEventListener('beforeunload',handleReload)
        return ()=>window.removeEventListener('beforeunload', handleReload)
    },[])
  return (
    <div className='relative'>
        {isMobile ? <MobileRotation /> : null}
        <Header/>
        <Intro/>
        <AppProvider>
          <DiagramComponent/>
        </AppProvider>
        <Content/>
        <Footer/>
    </div>
  );
}

export default App;
