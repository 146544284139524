import hero from '../../../assets/images/heroImage.png'
const Intro = ()=>{

    return(
        <div className='pt-18 grid grid-cols-12 '>
            <h1 className="col-start-2 col-span-10 mt-8 text-center font-bold tracking-widest text-sky-850 text-m lg:text-l mb-2 lg:mb-4 md:hidden">
                Երբ իրավունքը ստորադասվում է քաղաքական հայացքներին
            </h1>
            <div className='col-span-12 lg:col-span-10 lg:col-start-2 mx-auto px-4'>
                <div className='mt-8 md:mt-24 text-left'>
                    <img src={hero} alt="intro" className="w-full pb-4"/>
                    <span className="text-xs text-gray-850">3 դեկտեմբեր 2021</span>
                    <p className="mt-4 md:mt-12">
                        Դեկտեմբերի 5-ին Կոտայքի մարզի Աբովյան քաղաքում տեղի են ունենալու տեղական ինքնակառավարման մարմինների ընտրություններ։ Իշխող «Քաղաքացիական պայմանագիր» կուսակցության առաջին համարը՝ Պավել Ցուգունյանը, ով ցանկանում է դառնալ Աբովյանի քաղաքապետ, <a href='https://www.armtimes.com/hy/article/225683?fbclid=IwAR2dN47zFddYseKnYzcD1AoBazL-zLpfUzuFjGF_zvT64CmwIL2RnvVX3Fw' target='_blank' rel='noreferrer' >«Հայկական ժամանակի» հարցին</a>, թե ինչո՞ւ նախորդ ընտրություններին ՔՊ թեկնածուն չհաղթեց, պատասխանել է.
                    </p>
                    <p>
                        «2019-ին Աբովյանում կար վախի մթնոլորտ: Շատերը մտածում էին՝ եթե չընտրեն, ապա աշխատանքից կզրկվեն: Այս 3 տարվա ընթացքում մենք ապացուցեցինք, որ յուրաքանչյուր քաղաքացի օրենքով պաշտպանված է»,- նշվում է Ցուգունյանի պատասխանում:
                    </p>
                    <p>
                        Հետք Մեդիա Գործարանին դիմել են Աբովյանի բնակիչներ՝ նշելով, որ զրկվել են աշխատանքից՝ ՔՊ-ին չսատարող քաղաքական հայացքների պատճառով։ Մենք որոշեցինք ուսումնասիրել այս դեպքերը, սակայն մինչ այդ փոքր անդրադարձ կատարենք Կոտայքի մարզում ՔՊ կուսակցության ներկայացուցիչների վարած կադրային քաղաքականությանը՝ «Կոտայք» ջրօգտագործման ընկերության օրինակով։
                    </p>
                    <p>
                        Պավել Ցուգունյանը Շրջակա միջավայրի նախարար Ռոմանոս Պետրոսյանի կնոջ քեռորդին է։ Պետրոսյանի՝ նախարար դառնալուց հետո Ցուգունյանը նշանակվեց «Հայանտառ» ՊՈԱԿ-ի տնօրենի պաշտոնակատար, իսկ մինչ այդ, երբ Ռոմանոս Պետրոսյանը Կոտայքի մարզպետն էր, Պավել Ցուգունյանը նրա <a href='http://kotayk.mtad.am/files/legislation/35108.pdf' target='_blank' rel='noreferrer'>տեղակալի օգնականն էր</a>, այնուհետև «Կոտայք» ջրօգտագործողների ընկերության տնօրենը։
                    </p>
                    <p>
                        Այն, որ Ռոմանոս Պետրոսյանն իր պաշտոնեական լծակներն օգտագործել է <a href='https://infocom.am/hy/Article/51647' target='_blank' rel='noreferrer'>մտերիմներին պաշտոնների նշանակելու</a>, իսկ մյուսներին <a href='https://hetq.am/hy/article/135424?fbclid=IwAR2gMKCgmP1T13bl-BnKkxHlM1xUhgE_2VB9G88osr7pkn0YJZtpVLfPBfc' target='_blank' rel='noreferrer'>աշխատանքից ապօրինի ազատելու</a> համար, նորություն չէ։
                    </p>
                    <p>
                        «Կոտայք» ջրօգտագործողների ընկերությունը Ջրային կոմիտեի ստորաբաժանումներից է, որը քաղաքացիներին ոռոգման ջուր է մատակարարում։ Այս ընկերությունում վերջին երեք տարիներին պաշտոններ են ստացել Ռոմանոս Պետրոսյանի և նրա կնոջ հարազատները։
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Intro
