import { useRef, useEffect, createRef, useContext, useState } from 'react';
import styles from './index.module.scss';
import { diagramGridItems } from "./constants";
import {AppContext} from '../context'
import {activateImage, activateName, activeImage, activeName, activateSafariImage, safariImage,  diagramImage, diagramName } from './index.module.scss'

const DiagramGrid = () => {
  const nameRefs = useRef({});
  const imageRefs = useRef({});
  const [isMobile, setIsMobile] = useState(true);
  const { currentState } = useContext(AppContext)

  imageRefs.current = diagramGridItems.reduce((p, c) => {
      p[c.id] = imageRefs.current[c.id] ?? createRef();
      return p;
      }, {});

  nameRefs.current = diagramGridItems.reduce((p, c) => {
    p[c.id] = nameRefs.current[c.id] ?? createRef();
    return p;
  }, {});

  useEffect(()=> {
    diagramGridItems.forEach(elem =>
      nameRefs.current[elem.id].current.innerHTML = elem.name
    );

    if(window.safari) {
      let safariImages = document.getElementsByClassName('diagramActiveImage');
      for(let i=0; i<safariImages.length; i++) {
        safariImages[i].classList.remove(diagramImage);
      }
    }
    function handleResize()  {
      if(window.innerWidth < 767) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  useEffect(()=>{
    let foundElem = diagramGridItems.find(elem => currentState === elem.relatedTextId);
    if(foundElem) {
      if(window.safari){
        imageRefs?.current[foundElem.id]?.current.classList.add(safariImage);
        imageRefs?.current[foundElem.id]?.current.classList.add(activateSafariImage);
        let safariImages = document.getElementsByClassName('diagramActiveImage');
        for(let i=0; i<safariImages.length; i++) {
          safariImages[i].classList.remove(diagramImage);
        }
      } else {
        imageRefs?.current[foundElem.id]?.current.classList.add(activateImage);
      }
      nameRefs?.current[foundElem.id]?.current.classList.add(activateName);
    }
  }, [currentState])

  return (
    <div className={`${styles.diagramGrid} bg-coolGray-50`}>
      {diagramGridItems.map((elem, idx) =>
        <div key={idx} className={`${styles.diagramGridItem} flex flex-col`}>
          { elem.name !== '' ?
            <>
              <img className={`${isMobile ? activeImage : diagramImage} h-20 w-20 lg:h-30 lg:w-30 diagramActiveImage`} src={elem?.imageData?.src} alt={elem?.imageData?.alt} ref={imageRefs?.current[elem.id]}/>
              <span className={`${isMobile ? activeName : diagramName} text-gray-750 text-center h-13 lg:h-16 text-body-small lg:text-xs`} ref={nameRefs?.current[elem.id]}/>
            </>
            : <div ref={nameRefs?.current[elem.id]}/>
          }
        </div>
      )}
    </div>
  )
}

export default DiagramGrid;
