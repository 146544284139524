import { useRef, useEffect, createRef, useCallback } from 'react';
import styles from './index.module.scss';
import { diagramTextItems } from './constants';
import {useContext} from 'react'
import {AppContext} from '../context'

const DiagramText = () => {
  const textRefs = useRef({});
  const { handleState } = useContext(AppContext)

  const scrollHandler = useCallback(()=>{
    const elem = diagramTextItems.find(elem => {
        return textRefs?.current[elem.id]?.current?.getBoundingClientRect().bottom > 100 && textRefs?.current[elem.id]?.current?.getBoundingClientRect().top < window.innerHeight
    });
    if(elem){
      handleState(elem.id)
    }
  }, [ handleState ]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  textRefs.current = diagramTextItems.reduce((p, c) => {
    p[c.id] = textRefs.current[c.id] ?? createRef();
    return p;
  }, {});

  useEffect(()=> {
    diagramTextItems.map(elem =>
      textRefs.current[elem.id].current.innerHTML = elem.text
    )
  }, [])
  return (
    <div className={styles.diagramText}>
      {diagramTextItems.map((elem, idx) =>
        <div key={idx} className={`${styles.diagramTextItem}`}>
          <h3 className="mb-6 md:mb-8 mt-12 md:mt-0">{elem.name}</h3>
          <p ref={textRefs?.current[elem.id]}></p>
        </div>
      )}
    </div>
  )
}

export default DiagramText;