import {useState, useEffect} from 'react';
import styles from './index.module.scss'
import rotate1 from '../../assets/images/rotate1.svg';
import rotate2 from '../../assets/images/rotate2.svg';
import close from '../../assets/images/close.svg';
import {modalOpen, modalClose, lockScroll} from './index.module.scss'

const MobileRotation = () =>{
  const [open, setOpen] = useState(true);
  const [locked, setLocked] = useState(true);
  useEffect(()=>{
    function handleResize()  {
      setOpen(false);
      setLocked(false)
      document.getElementsByTagName('body')[0].classList.remove(lockScroll);
      document.getElementsByTagName('html')[0].classList.remove(lockScroll);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])
  useEffect(()=>{
    if(locked) {
      document.getElementsByTagName('body')[0].classList.add(lockScroll);
      document.getElementsByTagName('html')[0].classList.add(lockScroll);
    }
  }, [locked])
  const handleClick = () => {
    setOpen(false);
    document.getElementsByTagName('body')[0].classList.remove(lockScroll);
    document.getElementsByTagName('html')[0].classList.remove(lockScroll);
  }
  return(
    <div className={`${open ? modalOpen : modalClose}`}>
      <div className="absolute top-10 right-10" onClick={handleClick}>
        <img src={close} alt='close'/>
      </div>
      <img src={rotate1} alt='rotate frame 1' className={`${styles.image1} absolute top-0 left-0 h-3/5 w-3/5 m-0 m-auto`}/>
      <img src={rotate2} alt='rotate frame 2' className={`${styles.image2} absolute top-0 left-0 h-3/5 w-3/5 m-0 m-auto`}/>
      <span className="absolute bottom-16 left-0 m-0 m-auto text-center w-full">ՊՏՏԵՔ ՁԵՐ ՀԵՌԱԽՈՍԸ</span>
    </div>
  )
}

export  default MobileRotation;
