import avet from '../../../assets/images/Ավետ Կոնջորյան 1.jpg'
import hasmik from '../../../assets/images/Հասմիկ Խաչատրյան 1.jpg'
import bacatragirOne from '../../../assets/images/Batsatragir1.jpg'
import bacatragirTwo from '../../../assets/images/Batsatragir2.jpg'
import sedrakZekycagir from '../../../assets/images/Sedrak Martikyan_zekutsagir 1.jpg'
import vladik from '../../../assets/images/Վլադիկ-Աբրոյան.jpg'
import hovhannes from '../../../assets/images/Հովհաննես-Աբրահամյան.jpg'
import abrahamyanZekucagir from '../../../assets/images/zekutsagir_Abrahamyan 1.jpg'
import gayane from '../../../assets/images/Գայանե Զեյնալյան 1.jpg'


const Content = ()=>{

    return(
        <div className='pt-8 md:pt-35 grid grid-cols-8 lg:grid-cols-12 mx-auto px-8 lg:px-16'>
            <div className='col-span-8 md:col-span-6 lg:col-span-8 md:col-start-2 lg:col-start-3 text-left'>
                <img className='w-full' src={avet} alt='avet'/>
                <h2 className='pt-4'><a href='https://bit.ly/3nhzOTK' target='_blank' rel='noreferrer'>Ավետ Կոնջորյան</a></h2>
                <p className='mt-5'>
                    Բացի Ռոմանոս Պետրոսյանի ազգականներից՝  «Կոտայք» ՋՕԸ-ում է հաստատվել նաև ՔՊ խմբակցության ղեկավար Հայկ Կոնջորյանի եղբայրը՝ Ավետ Կոնջորյանը։ Նա 2021-ին նշանակվել է  «Կոտայք» ՋՕԸ-ի վերահսկողության բաժնի պետի տեղակալ։ Կոնջորյանը նույնպես ցանկանում է ընտրվել Աբովյանի ավագանի։
                </p>
                <p>
                    Ավետ Կոնջորյանը մինչև «Կոտայք» ՋՕԸ-ում աշխատելը զբաղվել է բնակարանների առք ու վաճառքով։ Դատալեքսից <a href='http://www.datalex.am/?app=AppCaseSearch&case_id=16325548649222207'>տեղեկանում ենք</a>, որ 2009 թվականին նրա նկատամբ հարուցվել է քրեական գործ և նա մեղավոր  է ճանաչվել մի խումբ անձանց կողմից նախնական համաձայնությամբ խարդախություն կատարելու մեջ (ՀՀ ՔՕ 178-րդ հոդվածի 3-րդ մասի 1-ին կետ): Նա, զբաղվելով բնակարանների առուվաճառքով, խաբեությամբ ուրիշի գույքին տիրանալու շուրջ նախնական համաձայնության է եկել Գ. Մեսրոպյանի հետ՝ հափշտակելով  9000 ԱՄՆ դոլլար։  Դատարանը նրա նկատմամբ պատիժ է սահմանել չորս տարի ազատազրկում, որից մոտ վեց ամիսը Կոնջորյանն անցկացրել է կալանավայրում, իսկ երեք տարի վեց ամսվա համար պատիժը պայմանականորեն չի կիրառվել և սահմանվել է փորձաշրջան՝ երեք տարի ժամկետով։
                </p>

                <h2 className='pt-8 pb-6 lg:pt-28 lg:pb-10'>«Մեր կուսակցության 600 ակտիվիստի պետք է տեղավորենք աշխատանքի»</h2>
                <p>
                    Հասմիկ Խաչատրյանը «Կոտայք» ՋՕԸ-ում սկսել է աշխատել 2018 թվականից՝ որպես գործավար-օպերատոր։ 2020 թվականի դեկտեմբերին ընկերության այժմյան տնօրեն Վլադիկ Աբրոյանը նրան առաջարկում է աշխատանքից ազատման դիմում գրել, քանի որ «այդպես է որոշվել»։
                </p>
                <p>
                    Խաչատրյանը հրաժարվում է դիմում գրել՝ նշելով, որ ինքն աշխատանքային որևէ խախտում չի արել, հետևապես չկա պատճառ՝ իրեն աշխատանքից ազատելու։ Ընկերության տնօրենն ասում է, որ «խոստանում է» ստեղծել նման պատճառներ, և մոտ երկու ամիս անց՝ 2021 թվականի փետրվարի 23-ին, Խաչատրյանին աշխատանքից հեռացման հրաման է տալիս։
                </p>
                <p>
                    Ազատման դիմում գրելու առաջարկից առաջ Հասմիկ Խաչատրյանը որևէ կարգապահական տույժ չի ունեցել, սակայն տնօրեն Աբրոյանի «խոստումից» հետո՝ երկու ամսվա ընթացքում, Խաչատրյանը ենթարկվել է երեք կարգապահական տույժի և հեռացվել  աշխատանքից։
                </p>
                <p>
                    2021-ի մարտից սկսած՝ Հասմիկ Խաչատրյանը Կոտայքի մարզի առաջին ատյանի դատարանում վիճարկում է կարգապահական տույժերն ու հեռացման հրամանը։
                </p>
                <p className='pb-8 lg:pb-28'>
                    Դեռ մինչև նոր տնօրենի՝ Վլադիկ Աբրոյանի նշանակումը, ՋՕԸ նախկին տնօրեն Պավել Ցուգունյանը Հասմիկ Խաչատրյանին ասել էր, որ իրենք 600 ակտիվիստ ունեն, որոնց պետք է աշխատանքի տեղավորեն։ Այս մասին մեզ հայտնել է Խաչատրյանի փաստաբան Գևորգ Մարտիրոսյանը։
                </p>
                <div className='relative overflow-hidden w-full pt-56.25%'>
                    <iframe title='Gevorg Martirosyan' className='absolute inset-0 h-full w-full'
                        src="https://www.youtube.com/embed/GLFueLXE3DE"/>
                </div>
                <p className='pt-4 pb-8 lg:pb-28'>
                    <em>«Երբ Հասմիկ Խաչատրյանին դեկտեմբեր ամսին անսպասելի առաջարկել են ազատման դիմում գրել, նա իր հերթին առաջարկել է իրեն տեղափոխել այլ բաժին, քանի որ տնօրենը պատճառաբանել էր, որ «այդպես է որոշված» և նրա տեղում այլ մարդ պետք է լինի։ Սակայն, Աբրոյանը չի համաձայնել Խաչատրյանին տեղափոխել այլ բաժին. նրանց նպատակն է եղել հնարավորինս արագ ազատվել աշխատակցից»</em>,- ասում է փաստաբանը։
                </p>
                <img  className='w-full' src={hasmik} alt='hasmik'/>
                <p className='pt-4'>
                    Ըստ փաստաբանի՝ Հասմիկ Խաչատրյանը խտրականության զոհ է դարձել՝ ընկերության ղեկավարի քաղաքական հայացքները չկիսելու պատճառով։ Դրա մասին վկայում են նաև ապօրինի կարգապահական տույժերի հիմքերը։
                </p>
                <p className='pb-8 lg:pb-28'>
                    Հասմիկ Խաչատրյանը նույնպես մասնակցում է ավագանու ընտրություններին՝ «Բարգավաճ Հայաստան» կուսակցության ցուցակով։
                </p>
                <div className='relative overflow-hidden w-full pt-56.25%'>
                    <iframe className='absolute inset-0 h-full w-full'
                            src="https://www.youtube.com/embed/UutQiWdJmOw"
                            title='datakan nist'/>
                </div>
                <p className='pt-4 pb-8 lg:pb-28'>
                    <em> «Այս տույժերը տեսնելով` ես ապշեցի թե ինչ ցածր մակարդակի կարող են հիմքերը լինել։ Հասմիկ Խաչատրյանին կարգապահական տույժի են ենթարկել հինգ-տասը րոպե աշխատանքի վայրից բացակայելու համար, ըստ իրենց պատկերացումների աշխատանքի վայրը հենց աթոռն է, որտեղ նա նստում է։ Երբ տնօրենը նրան հարցրել է, թե որտեղ է եղել, Խաչատրյանն ասել է, որ եղել է սանհանգույցում, փոխարենը տնօրենը պարտավորեցրել է գրավոր զեկուցել, թե որտեղ է եղել աշխատանքի ժամին։ Իրավական մասը մի կողմ թողնենք, բայց ես  չեմ պատկերացնում այդ արարքի բարոյական մասը, երբ ղեկավարը պարտադրում է կնոջը բացատրագիր գրել՝ սանհանգույցում լինելու համար։ Եվ հայցվորը, ստիպված վատ զգալով իրեն, գրում է այդ բացատրագիրը, չնայած նրան, որ այդ սանհանգույցից պարբերաբար օգտվում են նաև ընկերության մյուս աշխատակիցները»։ </em>
                </p>
                <div className='flex flex-row flex-wrap justify-evenly'>
                    <img className='w-full lg:w-1/2' src={bacatragirOne} alt='bacatragir1'/>
                    <img className='w-full lg:w-1/2' src={bacatragirTwo} alt='bacatragir2'/>
                </div>
                <p className='pt-4'>
                    Հասմիկ Խաչատրյանի գրավոր բացատրագիրն անտեսելով՝ ընկերության ղեկավարը նրան կարգապահական տույժի է ենթարկում՝ պատճառաբանելով, որ նրան աշխատակիցները չեն տեսել ընկերության առաջին հարկում, որտեղ և սանհանգույցն է։ Փաստացի, Հասմիկ Խաչատրյանը աշխատանքից չի բացակայել, բայց կարգապահական տույժը հենց բացակայելու հիմքով է տրվել։
                </p>
                <p>
                    Խաչատրյանը տույժի է ենթարկվել նաև ընկերության վերահսկողության բաժնի պետ Վալենտին Պապյանին տնօրենի հրավիրած ժողովի մասին չզեկուցելու համար։ Խաչատրյանը՝ տնօրենին ուղղված գրավոր բացատրագրի մեջ նշում է, որ <em>«հիմնարկի տարածքում գտնվող բոլոր  բաժինների աշխատողներին և ներքին հեռախոսակապ ունեցող բաժիններին տեղեկացվել է ժողովի մասին»։</em>
                </p>
                <p>
                    Ի տարբերություն մնացած բաժինների, որոնք գտնվում են «Կոտայք» ՋՕԸ-ի Բալահովիտի գլխամասային շենքում, վերահսկողության բաժինը տեղակայված է եղել Հրազդանում և ընկերության հետ ներքին հեռախոսակապ չի ունեցել։ Նշենք, որ նախարարի կնոջ ազգական, վերահսկողության բաժնի պետ Վալենտին Պապյանը Հրազդանի բնակիչ է։
                </p>
                <p className='pt-4'>
                    Հաջորդ տույժը, որին ենթարկվել է Խաչատրյանը, բժշկի այցելության համար է եղել։ <em>«2020թ. դեկտեմբերի 14-ից մինչև 2021 թվականի փետրվարի 23-ը ամեն աշխատանքային օր ընկերության տնօրենը, չարաշահելով իր դիրքը, ինձ ենթարկել է հոգեբանական ճնշումների, ինչի արդյունքում ունեցա առողջական խնդիրներ։ Փետրվարի 22-ին այցելել եմ ընտանեկան բժշկիս։ Եզրակացություն՝ ծանր սթրես, ցուցում՝ հանգիստ և դեղորայքային բուժում»,</em>- մեզ հետ զրույցում պատմում է Հասմիկ Խաչատրյանը։
                </p>
                <p>
                    Ըստ փաստաբանի՝ երբ առավոտյան Հասմիկ Խաչատրյանի ինքնազգացողությունը վատանում է, և նա դիմում է բժշկական կենտրոն, նախօրոք ընկերության տնօրենին և կադրերի տեսուչին տեղեկացնում է, որ աշխատանքի կներկայանա ավելի ուշ։
                </p>
                <p>
                    <em>«Հետազոտություններ անցնելուց հետո նա ներկայանում է աշխատանքի։ Տնօրենը գրավոր բացատրություն է պահանջում, թե ինչու է մի քանի ժամով բացակայել աշխատանքից։ Աբրոյանի պահանջին ի պատասխան՝ Խաչատրյանը ներկայացնում է բժշկի տեղեկանքը և հայտնում, որ իրեն բուժում է նշանակվել և դիմում է գրում՝ խնդրելով տրամադրել իրեն հասանելիք արձակուրդը»,</em>- Հետք Մեդիա Գործարանի հետ զրույցում նշում է Գևորգ Մարտիրոսյանը։
                </p>
                <p>
                    Տնօրեն Վլադիկ Աբրոյանը հրաժարվում է ստորագրել արձակուրդ տրամադրելու դիմումը` պատճառաբանելով, որ Հասմիկ Խաչատրյանը «բյուլետեն» չի ներկայացրել, իսկ բժշկի գնալն անհարգելի է համարում՝  պահանջելով երկու օրվա ընթացքում բացատրագիր ներկայացնել։
                </p>
                <p>
                    Փաստաբան Գևորգ Մարտիրոսյանն անտրամաբանական է համարում տնօրենի պատճառաբանությունը, քանի որ «բյուլետենը» ժամանակավոր անաշխատունակության թերթիկն է, որը գործատուին ներկայացվում է աշխատակցի կողմից՝ աշխատունակության վերականգնումից հետո։
                </p>
                <p>
                    <em>«Պահանջագրի մեջ նշված է, որ Հասմիկ Խաչատրյանը երկու օր ժամանակ ունի գրավոր տեղեկացնելու աշխատանքի չներկայանալու պատճառի մասին, բայց ղեկավարը այնքան է շտապել, որ հենց հաջորդ օրը նրան ազատել է աշխատանքից, որը նույնպես կոպիտ խախտում է։»,</em>- նշում է Գևորգ Մարտիրոսյանը։
                </p>
                <p>
                    Դատական գործընթացներին զուգահեռ փաստաբան Գևորգ Մարտիրոսյանը նաև դիմել է ՀՀ Առողջապահական և Աշխատանքի տեսչական մարմին՝ պահանջելով վերականգնել Հասմիկ Խաչատրյանի խախտված աշխատանքային իրավունքները։
                </p>
                <p>
                    Տեսչական մարմինը՝ իրենց ուղղված փաստաբանի դիմումի հիման վրա 2021 թվականի օգոստոսի 2-ին հարուցել է  վարչական վարույթ, որը, սակայն, կարճվել է՝ <em>«դատարանի վարույթում միևնույն անձանց միջև միևնույն առարկայի վերաբերյալ գործի առկայության հիմքով»։</em>
                </p>

                <h2 className='pt-8 md:pt-28 pb-4 md:pb-10'>Ֆեյսբուքում իշխանությանը քննադատող գրառումը կիսելու հետևանքը</h2>
                <p>
                    Սեդրակ Մարտիկյանը 2017 թվականից աշխատել է «Կոտայք» ՋՕԸ-ում` որպես գլխավոր ճարտարագետ։ Ըստ Մարտիկյանի փաստաբան Սուսաննա Սարգսյանի՝ ճարտարագետի հարաբերությունները ՋՕԸ-ի տնօրեն Վլադիկ Աբրոյանի հետ լարվել են 2020-ին, իսկ 2021 թվականի փետրվարի 12-ին Սեդրակ Մարտիկյանն ազատվել է աշխատանքից։
                </p>
                <p>
                    2021 թվականի մարտից Մարտիկյանը նույնպես իր ազատման հրամանը վիճարկում է Կոտայքի մարզի առաջին ատյանի դատարանում։
                </p>
                <p className='pb-8 md:pb-28'>
                    Փաստաբան Սուսաննա Սարգսյանը նշեց, որ գործատուի հետ խնդիրները սկսվել են այն ժամանակ, երբ Սեդրակ Մարտիկյանը ֆեյսբուքյան իր էջում կիսվել է իշխանությանը քննադատող հրապարակմամբ։
                </p>
                <div className='relative overflow-hidden w-full pt-56.25%'>
                    <iframe className='absolute inset-0 h-full w-full'
                            src="https://www.youtube.com/embed/GGahuj2nL1c"
                            title='Susanna Sargsyan'>
                    </iframe>
                </div>
                <p className='pt-4'>
                    <em>«Մարտիկյանը ընդդիմադիր դաշտում գտնվող անձանցից մեկի ընկերն է։ Պատահական չէ, որ իշխանության դեմ հրապարակված յուրաքանչյուր նյութ բացասական արձագանք է ստանում իշխանական ցանկացած թևի մոտ։  Սկսվել է ներանձնային խնդիրների սրացում, հարաբերությունների պարզաբանում։ Գործատուն աշխատանքային պարտականությունների խախտումներ չհայտնաբերելով՝ գնացել է ապօրինի ազատման հրաման արձակելու ճանապարհով»</em>,- ասում է Սուսաննա Սարգսյանը։
                </p>
                <p className='pt-8 md:pt-12 pb-4 md:pb-28'>
                    Ընկերության տնօրենը Սեդրակ Մարտիկյանի` հիմնարկից բացակայության պատճառով՝ առանց կարգապահական տույժ կիրառելու՝  նրան միանգամից ազատման հրաման է տվել։ Որպես ազատման հրամանի հիմք՝ հրամանում նշված է Մարտիկյանի աշխատանքի չներկայանալը։
                </p>
                <img  className='w-full' src={sedrakZekycagir} alt='zekucagir'/>
                <p className='pt-4'>
                    Սեդրակ Մարտիկյանը տնօրենին ուղղված բացատրագրում նշել է, որ նա գտնվել է իր սպասարկման տարածքում՝ քննարկելու Գառնի «ՀՈՎ-ԽԱՉ» ՍՊԸ-ի հետ աշխատանքային հարցերը, որոնցով տարիներ շարունակ զբաղվել է հենց ինքը՝ գլխավոր ճարտարագետը։
                </p>
                <p>
                    <em>«Դատարանում մեր կողմից բոլոր ապացույցները ներկայացված են, սակայն պատասխանող կողմը դատարանի պահանջած ազատման հրամանի հիմքում ընկած ապացույցները չի ներկայացրել մինչ օրս։ Չի էլ կարող ներկայացնել, քանի որ հրամանն ապօրինի է»</em>,- ասում է Սուսաննա Սարգսյանը։
                </p>
                <p>
                    Ըստ փաստաբանի` նման գործատուների անբարեխիղճ աշխատանքի պատճառով ավելանում է դատարանների ծանրաբեռնվածությունը։
                </p>
                <p>
                    <em>«Դատարանի պահանջով աշխատակիցները կվերականգնվեն իրենց հաստիքներում, հարկադիր պարապուրդի համար գործատուն կհատուցի աշխատակիցներին, բայց արդյո՞ք այն բարոյական վնասը, որ հասցվում է պետությանը, պետական մարմիններին, ՏԻՄ-ին, մի քանի հոգու՝  իրենց հասցեին ուղղված քննադատությունները ոչ ադեկվատ ընդունելու և բոլորի մեջ թշնամի տեսնելու համար, համարժեք է իրենց հասցրած վնասին»</em>,- եզրափակում է փաստաբանը։
                </p>
                <p className='pt-4 md:pt-8'>
                    <b>
                        «Կոտայք» ջրօգտագործողների ընկերության տնօրեն Վլադիկ Աբրոյանին առաջարկեցինք տեսակետ հայտնել նշված գործերի և իրեն ուղղված մեղադրանքների վերաբերյալ։ Նա հրաժարվեց մեկնաբանել՝ նշելով, որ ճիշտ չի համարում մինչև դատական գործի ավարտը կարծիք հայտնել։
                    </b>
                </p>

                <h2 className='pt-12 md:pt-28 pb-4 md:pb-10'>Ովքե՞ր են կուսակցության «գաղափարակից ակտիվիստները», որոնք աշխատում են Կոտայք ՋՕԸ-ում</h2>
                <img className='w-full' src={vladik} alt='vladik'/>
                <h3 className='py-2'><a href='https://www.facebook.com/profile.php?id=100012305010283' target='_blank' rel='noreferrer'>Վլադիկ Աբրոյան</a></h3>
                <span>«Կոտայք» ՋՕԸ—ի տնօրեն</span>
                <p className='pb-28'>
                    Վլադիկ Աբրոյանը «Կոտայք» ՋՕԸ-ում աշխատանքի է ընդունվել 2020 թվականին` որպես իրացման բաժնի պետ։ Սեպտեմբեր ամսին Վլադիկ Աբրոյանը նշանակվել է տնօրենի ժ/պ, իսկ 2021 թվականի ամռանն ընտրվել է տնօրեն՝ լինելով մրցույթին մասնակցած միակ թեկնածուն։
                </p>
                <img className='w-full' src={hovhannes} alt='hovhannes'/>
                <h3 className='md:pt-2'><a href='https://www.facebook.com/Hovo303' target='_blank' rel='noreferrer'>Հովհաննես Աբրահամյան</a></h3>
                <p className='pt-4'>
                    Հովհաննես Աբրահամյանը 2020 թվականին ՋԸՕ-ում աշխատանքի է ընդունվել՝ որպես իրացման բաժնի գլխավոր մասնագետ։ Վլադիկ Աբրոյանի տնօրենի ժ/պ դառնալուց հետո՝ Հովհաննես Աբրահամյանը նշանակվել է իրացման բաժնի պետ։
                </p>
                <p className='pb-28'>
                    Երբ նախարարի կնոջ ազգական, «Կոտայք» ՋՕԸ-ի նախկին տնօրեն Պավել Ցուգունյանը նշանակվել է  «Հայանտառ» ՊՈԱԿ-ի տնօրենի ժ/պ, Հովհաննես Աբրահամյանն իրացման բաժնի պետի պաշտոնից տեղափոխվել է «Հայանտառ» ՊՈԱԿ-ի «Հրազդանի անտառտնտեսություն»՝ որպես <a href='http://forestcommittee.am/%d5%b0%d6%80%d5%a1%d5%a6%d5%a4%d5%a1%d5%b6%d5%ab-%d5%a1%d5%b6%d5%bf%d5%a1%d5%bc%d5%bf%d5%b6%d5%bf%d5%a5%d5%bd%d5%b8%d6%82%d5%a9%d5%b5%d5%b8%d6%82%d5%b6-%d5%b4%d5%a1%d5%bd%d5%b6%d5%a1%d5%b3/' target='_blank' rel='noreferrer'>մասնաճյուղի պետ:</a>
                </p>
                <img className='w-full' src={abrahamyanZekucagir} alt='zekucagir'/>
                <p>
                    Ի դեպ՝ Հասմիկ Խաչատրյանի աշխատանքային իրավունքը վերականգնելու պահանջով դատական նիստի ժամանակ պատասխանող կողմը դատարան է ներկայացրել մի զեկուցագիր, որտեղ Հովհաննես Աբրահամյանը գրավոր տեղեկացրել է ընկերության ղեկավարին, որ նա աշխատանքային ժամին մոտ 15-20 րոպե գտնվել է առաջին հարկի միջանցքում, սակայն Հասմիկ Խաչատրյանին այնտեղ չի տեսել։ Այս զեկուցագիրն է հիմք հանդիսացել, որ ՋՕԸ տնօրենը կարգապահական տույժի ենթարկի Հասմիկ Խաչատրյանին՝ չհավատալով, որ վերջինս գտնվել է առաջին հարկի սանհանգույցում։
                </p>
                <p className='pb-28'>
                    Հովհաննես Աբրահամյանը դատական գործում այժմ ներգրավված է՝ որպես վկա, սակայն, դատաքննությանն արդեն երկրորդ անգամ չի ներկայացել։
                </p>
                <img className='w-full' src={gayane} alt='gayane'/>
                <h3 className='pt-2'><a href='https://www.facebook.com/gayane.zeynalyan.39' target='_blank' rel='noreferrer'>Գայանե Զեյնալյան</a></h3>
                <p className='pt-4'>
                    Գայանե Զեյնալյանը Կոտայք ՋՕԸ-ում աշխատանքի է ընդունվել Հասմիկ Խաչատրյանի փոխարեն։ Զեյնալյանը նույնպես մասնակցում է Աբովյանի ավագանու ընտրություններին՝ ՔՊ-ի ցուցակով։
                </p>
                <p>
                    Ավագանու անդամների թեկնածուների ցուցակում <a href='https://docs.google.com/spreadsheets/d/1Ed_xMco1Bi8rIk8-6SwIz9TsI4c8tkL-O9yss_6uHsA/edit#gid=530170535' target='_blank' rel='noreferrer'>ընդգրկված են</a> Շրջակա միջավայրի նախարարության 10 և «Կոտայք» ՋՕԸ-ի 4 աշխատակից։
                </p>

                <h2 className='pt-8 md:pt-28 pb-10'>Քաղաքական հայացքներն ու աշխատանքային իրավունքը </h2>
                <p>
                    «Հրայր Մարուխյան» հիմնադրամի աշխատանքային իրավունքների պաշտպանության գրասենյակը զբաղվում է աշխատանքային իրավունքը խախտված անձանց հարցերով։ Գրասենյակի փաստաբան Քրիստինե Ավագյանի խոսքով՝ ստացել են աշխատանքային իրավունքների խախտման մոտ 80 դիմում, որի 30 տոկոսը՝ քաղաքական հայացքների համար խտրականության հիմքով։
                </p>
                <p>
                    <em>«Աշխատանքային իրավունքների խախտման առումով խայտառակ իրադրություն է, մասնավորապես, մարզերում՝ տարբեր վարչական կառույցների ղեկավարության օրենսդրական իմացության ցածր մակարդակի պատճառով։ Տարբեր համայնքերում աշխատող մի շարք մարդիկ առանց հիմքերի ազատվել են աշխատանքից՝ քաղաքական խտրական վերաբերմունքի ենթարկվելով։ Մեզ դիմած մարդկանց դիմումներն ուսումնասիրելով պարզ է դառնում, որ իրենց վերադասները հրահանգ են ստացել ազատել այդ մարդկանց՝ առանց որևէ հիմքի»,</em>- ասում է փաստաբանը։
                </p>
                <p>
                    Քրիստինե Ավագյանը տեղեկացնում է, որ քաղաքական խտրականության հիմքով աշխատանքային իրավունքների խախտման դիմումներն ավելացել են պատերազմից հետո։ Փաստաբանի ենթադրությամբ՝ պատերազմի ընթացքում մարդիկ զայրացած քննադատությամբ են հանդես եկել իշխանության նկատմամբ, որի արդյունքում ազատվել են աշխատանքից։
                </p>
                <p>
                    <em>«Մեր վստահորդների խոսքով՝ իրենց աշխատանքից ազատել են, որովհետև կամ եղել են այլ կուսակցության անդամ կամ գաղափարակից, կամ ուղղակի լինելով անկուսակցական՝ իշխանության հասցեին քննադատական կարծիք են հայտնել»</em>,- մանրամասնում է Ավագյանը։
                </p>
                <p>
                    Աշխատանքային իրավունքների պաշտպանության գրասենյակ են դիմել համայնքների դպրոցների տնօրեններ, «Հայանտառ» ՊՈԱԿ-ի աշխատակիցներ, Սոցապ ծառայության նախկին աշխատակիցներ։
                </p>
                <p>
                    <em>«Մոտ 7-8 աշխատակից ապօրինի ազատվել են «Հայանտառ» ՊՈԱԿ-ից՝ կրճատման հիմքով։ Մեզ դիմած ՊՈԱԿ-ի աշխատակիցների խոսքով՝ նրանք քաղաքական խտրականության զոհ են, քանի որ քննադատել են իշխանությանը և կարճ ժամանակ անց նրանց հաստիքները կրճատվել են։ Չնայած դժվար է ապացուցել, որ հենց այդ հայտարարությունների պատճառով են նրանց հաստիքները կրճատվել, սակայն, հետաքրքիր զուգադիպությամբ կրճատվել են իշխանությանը քննադատող մարդկանց հաստիքները»</em>,- պատմում է փաստաբանը։
                </p>
                <p>
                    Հետաքրքիր է նաև «Հայանտառ» ՊՈԱԿ-ի Սյունիքի մասնաճյուղի տնօրենի աշխատանքից ազատման պատմությունը։ Տնօրենն ազատվել է աշխատանքից այն հիմքով, որ պատշաճ չի ստուգել իր վերահսկողության տակ գտնվող տարածքները, որի պատճառով Շուռնուխ գյուղի անտառները հատվել են։
                </p>
                <p>
                    <em>«Մեր վստահորդը փաստում է, որ այդ տարածքում զորք է տեղակայված եղել, անտառի հատվածը հատվել է, որ զինտեխիկա տեղավորվի, ինչպե՞ս կարող էր վերահսկել, որ ծառեր չհատվեին։ Բացի այդ, տարածքը եղել է ականապատ և անհնար է եղել հասկանալ, թե այդ պահին ու՞մ վերահսկողության տակ է եղել։ Այս դատական գործերի վճիռները դեռ չեն կայացվել, բայց մեր դիրքորոշումն այն է, որ սրանք բացահայտ քաղաքական խտրականության օրինակ են»</em>,- ասում է փաստաբանը։
                </p>
                <p>
                    Նմանօրինակ գրեթե բոլոր գործերը, որոնց վճիռն արդեն կայացվել է՝ եղել են հօգուտ ազատված աշխատողների։
                    Հիշեցնենք, որ «Հայանտառ» ՊՈԱԿ-ի տնօրենը Պավել Ցուգունյանն է, իսկ այն վերահսկող Շրջակա միջավայրի նախարարը՝ Ռոմանոս Պետրոսյանը։
                </p>
                <p>
                    Ռոմանոս Պետրոսյանի հրամանով աշխատանքից ազատված երկու անձ արդեն հաղթել է դատարանում և վերականգնվել աշխատանքի։ 2021թ-ի սեպտեմբերի 3-ին <a href='https://hetq.am/hy/article/135424?fbclid=IwAR2gMKCgmP1T13bl-BnKkxHlM1xUhgE_2VB9G88osr7pkn0YJZtpVLfPBfc' target='_blank' rel='noreferrer'>անվավեր է ճանաչվել</a> Շրջակա միջավայրի նախարար Ռոմանոս Պետրոսյանի՝ «Արգելոցապարկային համալիր» ՊՈԱԿ-ի տնօրեն Լևոն Գաբրիելյանի ազատման հրամանը, նրան վերականգնել են պաշտոնում և պարտավորեցրել Շրջակա միջավայրի նախարարությանը Գաբրիելյանին վճարել
                    նրա միջին աշխատավարձը ողջ պարապուրդի համար:
                </p>
                <p>
                    2020-ի օգոստոսին դատարանի որոշմամբ <a href='http://www.datalex.am/?app=AppCaseSearch&case_id=16325548649325395' target='_blank' rel='noreferrer'>անվավեր է ճանաչվել</a> այդ ժամանակ Կոտայքի մարզպետ Ռոմանոս Պետրոսյանի հրամանը՝ Աբովյանի թիվ 2 հիմնական դպրոց ՊՈԱԿ-ի տնօրեն Սվետլանա Հովհաննիսյանին նկատողություն տալու և աշխատանքից ազատելու մասին։ Հովհաննիսյանը վերականգնվել է դպրոցի տնօրենի պաշտոնում, իսկ Կոտայքի մարզպետարանին դատարանը պարտավորեցրել է վճարել տնօրենի հարկադիր պարապուրդի ամբողջ ժամանակահատվածի համար։
                </p>
                <p className='pb-28'>
                    «Կոտայք» ՋՕԸ-ի կադրային քաղաքականությունը փոքրիկ օրինակ է, որն ընդգծում է աշխատանքային իրավունքի ստորադասումը քաղաքական համախոհությանը։ Հատկանշական է, որ Ռոմանոս Պետրոսյանի և նրա թիմակիցների կադրային քաղաքականության հետևանքով ֆինանսական պարտավորություններ են ստանձնում պետական կառույցները։
                </p>
            </div>
        </div>
    )

}

export default Content