import petrosyan1 from '../../../assets/images/petrosyan1.png';
import petrosyan2 from '../../../assets/images/petrosyan2.png';
import cugunyan from '../../../assets/images/cugunyan.png';
import osipyan from '../../../assets/images/osipyan.png';
import aghabekyan from '../../../assets/images/aghabekyan.png';

export const diagramGridItems = [
  {imageData: {
      src: '',
      alt: ''
    },
    name: '',
    id: '37891040-51dc',
    relatedTextId: ''
  },
  {
    imageData: {
      src: petrosyan1,
      alt: 'Romanos Petrosyan'
    },
    name: 'Ռոմանոս և <br/> Արմինե<br/>  Պետրոսյաններ',
    id: '37891040-52dc',
    relatedTextId: 'ded17df7-7745-498e-8359-7159752ba106'
  },
  {imageData: {
      src: '',
      alt: ''
    },
    name: '',
    id: '37891040-53dc',
    relatedTextId: ''
  },
  {imageData: {
      src: '',
      alt: ''
    },
    name: '',
    id: '37891040-54dc',
    relatedTextId: ''
  },
  {
    imageData: {
      src: petrosyan2,
      alt: 'Ruben Petrosyan'
    },
    name: 'Ռուբեն<br/> Պետրոսյան',
    id: '37891040-55dc',
    relatedTextId: '09ba4e69-39ee-4798-ae6c-90c48c02ac8a'
  },
  {
    imageData: {
      src: '',
      alt: ''
    },
    name: '',
    id: '37891040-56dc',
    relatedTextId: ''
  },
  {
    imageData: {
      src: cugunyan,
      alt: 'Pavel Cugunyan'
    },
    name: 'Պավել<br/> Ցուգունյան',
    id: '37891040-57dc',
    relatedTextId: '09e72b06-d1aa-4175-8385-f150726463bf'
  },
  {
    imageData: {
      src: '',
      alt: ''
    },
    name: '',
    id: '37891040-58dc',
    relatedTextId: ''
  },
  {
    imageData: {
      src: '',
      alt: ''
    },
    name: '',
    id: '37891040-59dc',
    relatedTextId: ''
  },
  {
    imageData: {
      src: osipyan,
      alt: 'Gurgen Osipyan'
    },
    name: 'Գուրգեն<br/> Օսիպյան',
    id: '37891040-60dc',
    relatedTextId: '9347487e-1d96-4480-a712-28e6e768cf91'
  },
  {
    imageData: {
      src: '',
      alt: ''
    },
    name: '',
    id: '37891040-61dc',
    relatedTextId: ''
  },
  {
    imageData: {
      src: aghabekyan,
      alt: 'Ararat Aghabekyan'
    },
    name: 'Արարատ<br/> Աղաբեկյան',
    id: '37891040-62dc',
    relatedTextId: '9347487e-1d96-4480-a712-28e6e768cc85'
  },
]