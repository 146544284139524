export const diagramTextItems = [
  {
    name: '',
    text: 'Ջրօգտագործողների այս ընկերությունում վերջին երեք տարիներին տարբեր պաշտոններ են ստացել նախարար Պետրոսյանի եղբայրը, կնոջ ազգականները, ինչպես նաև Քաղաքացիական պայմանագիր կուսակցության գաղափարակից «ակտիվիստները»։',
    id: 'ded17df7-7745-498e-8359-7159752ba106',
  },
  {
    name: <a href="https://www.facebook.com/ruben.petrosyan.94" target="_blank" rel="noreferrer"> Ռուբեն Պետրոսյան</a>,
    text: `Ռուբեն Պետրոսյանը Ռոմանոս Պետրոսյանի եղբայրն է։ Նա 2021 թվականից նշանակվել է «Կոտայք» ՋԸՕ-ի տնօրենի տեղակալ։ Վերջինս <a href="https://www.facebook.com/ruben.petrosyan.94" target="_blank" rel="noreferrer">սոցիալական ցանցերում</a> հստակ քաղաքական դիրքորոշում է հայտնում՝ սատարելով «Քաղաքացիական պայմանագիր» կուսակցությանը և իշխանությանը։`,
    id: '09ba4e69-39ee-4798-ae6c-90c48c02ac8a',
  },
  {
    name: <a href="https://www.facebook.com/pavel.cugunyan" target="_blank" rel="noreferrer">Պավել Ցուգունյան</a>,
    text: `Պավել Ցուգունյանը Ռոմանոս Պետրոսյանի կնոջ՝ Արմինե Պետրոսյանի քեռու որդին է։ Մասնակցում է Կոտայքի մարզի ՏԻՄ ընտրություններին՝ գլխավորելով «Քաղաքացիական պայմանագիր» կուսակցության ցուցակը։<br/>
      Ռոմանոս Պետրոսյանի՝ Կոտայքի մարզպետ նշանակվելուց հետո, Պավել Ցուգունյանն աշխատել է որպես <a href="http://kotayk.mtad.am/files/legislation/35108.pdf" target="_blank" rel="noreferrer">մարզպետի տեղակալի օգնական</a>,այնուհետև «Կոտայք» ՋՕԸ-ի տնօրեն:<br/>
      Երբ 2020 թվականի հուլիսին Ռոմանոս Պետրոսյանը նշանակվել է շրջակա միջավայրի նախարար, նույն թվականի սեպտեմբերից մինչ օրս նախարարի կնոջ ազգականը <a href="http://forestcommittee.am/2021/03/18/%d5%ba%d5%a1%d5%be%d5%a5%d5%ac-%d6%81%d5%b8%d6%82%d5%a3%d5%b8%d6%82%d5%b6%d5%b5%d5%a1%d5%b6%d5%a8-%d5%b6%d5%b7%d5%a1%d5%b6%d5%a1%d5%af%d5%be%d5%a5%d5%ac-%d5%a7-%d5%b0%d5%a1%d5%b5%d5%a1%d5%b6/" target="_blank" rel="noreferrer">տեղափոխվել է</a> «Հայանտառ» ՊՈԱԿ՝ որպես <a href="http://forestcommittee.am/%D5%B0%D5%A1%D5%B5%D5%A1%D5%B6%D5%BF%D5%A1%D5%BC-%D5%BA%D5%B8%D5%A1%D5%AF-%D5%AB-%D5%BF%D5%B6%D6%85%D6%80%D5%A5%D5%B6%D5%AB-%D5%BA%D5%A1%D5%B7%D5%BF%D5%B8%D5%B6%D5%A1%D5%AF%D5%A1%D5%BF/" target="_blank" rel="noreferrer">տնօրենի պաշտոնակատար։</a>`,
    id: '09e72b06-d1aa-4175-8385-f150726463bf'
  },
  {
    name: <a href="https://www.facebook.com/gugo.osipyan.5" target="_blank" rel="noreferrer">Գուրգեն Օսիպյան</a>,
    text: `Գուրգեն Օսիպյանը Պավել Ցուգունյանի մորաքրոջ տղան է։ Նա 2019 թվականի ապրիլին ընդունվել է «Կոտայք» ՋՕԸ՝ որպես «Նաիրի» տեղամասի պետի տեղակալ, իսկ 2020-ի հունվարին դարձել տեղամասի պետ։`,
    id: '9347487e-1d96-4480-a712-28e6e768cf91'
  },
  {
    name: <a href="https://bit.ly/2TtZcZZ" target="_blank" rel="noreferrer">Արարատ Աղաբեկյան</a>,
    text: 'Արարատ Աղաբեկյանը Պավել Ցուգունյանի քրոջ ամուսինն է։ 2019-ի ամռանը  նա դարձել է «Կոտայք» ՋՕԸ-ի «Ջրվեժ-Ձորաղբյուր» տեղամասի պետի տեղակալ, իսկ 2020-ի հունվարից նշանակվել է այդ տեղամասի պետ։',
    id: '9347487e-1d96-4480-a712-28e6e768cc85'
  }
]
