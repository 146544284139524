const Footer = ()=>{

    return(
        <footer className='py-2 lg:h-15 bg-gray-350 bg-opacity-15 flex flex-col justify-center'>
            <p className='text-center my-2'>Հետք Մեդիա Գործարանի հետաքննող թիմ</p>
            <span className="lg:absolute lg:right-4 text-xs text-gray-850 text-center">© 2021 Hetq Media Factory</span>
        </footer>
    )
}

export default Footer