import React, { useState, createContext } from 'react'

const AppContext = createContext({})

const AppProvider = props => {
  const [currentState, setCurrentState] = useState(1)

  const handleState = value => {
    setCurrentState(value)
  }

  const contextValue = { handleState, currentState }

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  )
}

export {AppContext, AppProvider}