import { useEffect, useState, useContext } from 'react';
import styles from './index.module.scss';
import DiagramGrid from "./DiagramGrid";
import DiagramText from "./DiagramText";
import {AppContext} from "./context";
import {diagramTextItems} from "./DiagramText/constants";
import {activateLine, relativeSvgWrapperMobile, relativeSvgWrapperDesktop, activeLine, windowHeightIsSmaller, relativeSvgWrapperTablet} from "./index.module.scss";

const DiagramComponent = () => {
  const [points, setPoints] = useState({});
  const { currentState } = useContext(AppContext);
  const [isMobile, setIsMobile] = useState(true);
  const [isTablet, setIsTablet] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);
  const [heightIsSmaller, setHeightIsSmaller] = useState(true);
  const tabletMax = 1096;
  const mobileMax = 767;

  useEffect(() => {
     function handleResize()  {
       if(window.innerHeight < window.innerWidth && window.innerWidth <= tabletMax) {
         setHeightIsSmaller(true);
       } else  {
         setHeightIsSmaller(false);
       }
       if(window.innerWidth > tabletMax) {
         setIsDesktop(true)
       }
       else  {
         setIsDesktop(false);
       }
       if(window.innerWidth > mobileMax && window.innerWidth <= tabletMax) {
         setIsTablet(true);
       } else  {
         setIsTablet(false);
       }
       if(window.innerWidth <= mobileMax) {
         setIsMobile(true);
       } else {
         setIsMobile(false);
       }
       if(window.innerWidth <= tabletMax) {
          setPoints({
            points1to2: "40 140 120 40",
            points1to3: "200 140 120 40",
            points3to4: "120 280 200 160",
            points3to5: "280 280 200 160"
          });
        } else {
          setPoints({
            points1to2: "60 240 180 60",
            points1to3: "300 240 180 60",
            points3to4: "180 420 300 240",
            points3to5: "420 420 300 240"
          });
       }
      }
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if(window.innerWidth > mobileMax) {
      const elem = diagramTextItems.find((elem, idx) => {
        return elem.id === currentState && idx !== 0
      });
      const lines = document.getElementsByTagName('polyline');
      const idx = diagramTextItems.indexOf(elem)
      if (idx >= 1) {
        if(!window.safari) {
          lines[idx - 1].classList.add(activateLine);
        }
      }
    }
  }, [currentState])

  return(
    <div className={`${styles.allWrapper} mt-8 md:mt-10 lg:mt-16`}>
      <div className="pt-10 pb-24 md:pb-0 lg:pb-32 bg-gray-50">
        <div className={`${styles.grid}`}>
          <DiagramText />

          <div className={`${styles.relativeSvgWrapper} ${isMobile ? relativeSvgWrapperMobile : ''} ${isTablet ? relativeSvgWrapperTablet : ''} ${isDesktop ? relativeSvgWrapperDesktop : ''} ${heightIsSmaller ? windowHeightIsSmaller : '' }`}>
            <div className={styles.svgWrapper}>
              <svg className={styles.svg}>
                <polyline id="1to2" className={`${styles.polyline} ${isMobile ? activeLine : ''}`} points={points.points1to2} stroke="#666666" strokeWidth="3"/>
                <polyline id="1to3" className={`${styles.polyline} ${isMobile ? activeLine : ''}`} points={points.points1to3} stroke="#666666" strokeWidth="3"/>
                <polyline id="3to4" className={`${styles.polyline} ${isMobile ? activeLine : ''}`} points={points.points3to4} stroke="#666666" strokeWidth="3"/>
                <polyline id="3to5" className={`${styles.polyline} ${isMobile ? activeLine : ''}`} points={points.points3to5} stroke="#666666" strokeWidth="3"/>
              </svg>
            </div>
            <DiagramGrid/>
          </div>

        </div>
      </div>
      </div>
  )
}

export default DiagramComponent;
