import Logo from '../../../assets/logos/Hetq.png';

const scrollTop = ()=>{
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

const Header = ()=>{

    return (
        <header className="bg-white w-full fixed shadow-xl h-16 md:h-20 z-10">
            <div className="flex flex-row mx-auto px-4 lg:mx-16 items-center">
                <div>
                    <img
                        className="w-18 md:w-22 cursor-pointer"
                        src={ Logo }
                        alt = 'logo'
                        onClick={scrollTop}
                    />
                </div>

                <h1 className="flex-grow text-center font-bold tracking-widest text-sky-850 text-m lg:text-l mb-2 lg:mb-4 hidden md:block ">
                    Երբ իրավունքը ստորադասվում է քաղաքական հայացքներին
                </h1>

            </div>
        </header>
    )
}

export default Header